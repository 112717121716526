/* custom css for slider */
.embla {
    width: 100%;
    overflow: hidden;
    --text-body: rgb(54, 49, 61);
    --detail-medium-contrast: rgb(234, 234, 234);
    --text-high-contrast-rgb-value: 49, 49, 49;
}

.embla__container {
    display: flex;
    margin: 0 12px;
}

.embla__slide {
    flex: 0 0 auto; /* Adapt slide size to its content */
    min-width: 0;
    max-width: 100%; /* Prevent from growing larger than viewport */
}
.embla__controls {
    padding-right: 12px;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 8px;
}

.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}
.embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 6px;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.embla__dot:after {
    background-color: #475467;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
}
.embla__dot--selected:after {
    background-color: var(--color);
}
