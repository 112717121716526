.inner {
    position: absolute;
    margin: 0 auto;
    transform: translate(-50%, -100%);
    /*box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    */opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    /*text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
     */
    -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.inner.show {
    transform: translate(-50%, -115%);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    -webkit-transform: translate(-50%, -115%);
    -moz-transform: translate(-50%, -115%);
    -ms-transform: translate(-50%, -115%);
    -o-transform: translate(-50%, -115%);
}
.bottomShow{
    transform: translate(-50%, 16%);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    top:100%;
    -webkit-transform: translate(-50%, 16%);
    -moz-transform: translate(-50%, 16%);
    -ms-transform: translate(-50%, 16%);
    -o-transform: translate(-50%, 16%);
}

.inner:before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #1d2939;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.bottomShow:before{
    top: -3px;
}
