.rdp-day_button {
    width: 40px;
    height: 40px;
}
/* .rdp-day_button:hover {
    background-color: #cff9fe;
    border-radius: 100%;
    color: #0e7090;
} */
.rdp-weekday {
    width: 40px;
    height: 40px;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.rdp-day_button:disabled {
    color: #d0d5dd;
}
