:root {
    --rt-opacity: 1 !important;
}

.wrapper {
    position: absolute;
    height: 1px;
    width: 1px;
}

.wrapper .icon {
    position: relative;
    background: #ffffff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/* .wrapper .icon::after {
    content: "";
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNy4wMjc0NCAtMS40ODMyOEM3LjczODIyIC0xLjQ4MzIgOC4yMjIwMSAtMC43NjI0NDIgNy45NTI4NSAtMC4xMDQ1OTZMNC45MjU2NyA3LjI5Mzk3QzQuNTg2NyA4LjEyMjQ0IDMuNDEzNDYgOC4xMjIzOCAzLjA3NDU4IDcuMjkzODdMMC4wNDgwOTc5IC0wLjEwNTM5MUMtMC4yMjEwMDkgLTAuNzYzMzE0IDAuMjYyOTQ5IC0xLjQ4NDA2IDAuOTczNzc5IC0xLjQ4Mzk3TDcuMDI3NDQgLTEuNDgzMjhaIiBmaWxsPSIjMUQyOTM5Ii8+Cjwvc3ZnPgo=")
        no-repeat !important;
    background-repeat: no-repeat;
    width: 8px;
    height: 8px;
    position: absolute;
    left: -4px;
    top: 0;
} */

.wrapper .tooltip {
    position: absolute;
    transform: translate(-50%, -30%);
    top: 5px;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 4px;
    border-radius: 7px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.wrapper .tooltip.show.warning {
    padding: 4px 5px;
    box-shadow: 0px 4px 8px 2px #1018281a;
}

.icon {
    position: relative;
}

.icon > svg {
    position: absolute;
    left: -3.5px;
    bottom: -6px;
    display: none;
}

.wrapper .tooltip::before {
    /*position: absolute;
    content: "";
    background: #ffffff;
    */
    /*opacity: var(--tipOpacity);
    left: 50%;
    z-index: -1;
    transform: translate(-49%);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width: 0;
    height: 0;
    background: initial !important;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 21px solid var(--color);
    -webkit-transform: translate(-49%);
    -moz-transform: translate(-49%);
    -ms-transform: translate(-49%);
    -o-transform: translate(-49%);
    -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
     */
}

.wrapper .tooltip.show {
    transform: translate(-50%, -100%);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    box-shadow: 0px 4px 8px 4px #1018281a;
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
}

.wrapper .tooltip,
.wrapper .tooltip::before {
    background: var(--color);
    color: #ffffff;
}

.react-tooltip-content-wrapper {
    position: relative;
}

.custom-arrow {
    width: 8.5px !important;
    height: 8.5px !important;
}

.custom-arrow.red {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><g clip-path="url(%23clip0_1_31242)"><path d="M7.02744 -1.48328C7.73822 -1.4832 8.22201 -0.762442 7.95285 -0.104596L4.92567 7.29397C4.5867 8.12244 3.41346 8.12238 3.07458 7.29387L0.0480979 -0.105391C-0.221009 -0.763314 0.262949 -1.48406 0.973779 -1.48397L7.02744 -1.48328Z" fill="%23FEE4E2"/></g><defs><clipPath id="clip0_1_31242"><rect width="8" height="8" fill="white"/></clipPath></defs></svg>')
        no-repeat !important;
}

.custom-arrow.yellow {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><g clip-path="url(%23clip0_1_31242)"><path d="M7.02744 -1.48328C7.73822 -1.4832 8.22201 -0.762442 7.95285 -0.104596L4.92567 7.29397C4.5867 8.12244 3.41346 8.12238 3.07458 7.29387L0.0480979 -0.105391C-0.221009 -0.763314 0.262949 -1.48406 0.973779 -1.48397L7.02744 -1.48328Z" fill="%23FEF0C7"/></g><defs><clipPath id="clip0_1_31242"><rect width="8" height="8" fill="white"/></clipPath></defs></svg>')
        no-repeat !important;
}

.custom-arrow.green {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><g clip-path="url(%23clip0_1_31242)"><path d="M7.02744 -1.48328C7.73822 -1.4832 8.22201 -0.762442 7.95285 -0.104596L4.92567 7.29397C4.5867 8.12244 3.41346 8.12238 3.07458 7.29387L0.0480979 -0.105391C-0.221009 -0.763314 0.262949 -1.48406 0.973779 -1.48397L7.02744 -1.48328Z" fill="%23DCFAE6"/></g><defs><clipPath id="clip0_1_31242"><rect width="8" height="8" fill="white"/></clipPath></defs></svg>')
        no-repeat !important;
}

.custom-arrow.blue {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><g clip-path="url(%23clip0_2560_192491)"><path d="M7.02744 -1.48328C7.73822 -1.4832 8.22201 -0.762442 7.95285 -0.104596L4.92567 7.29397C4.5867 8.12244 3.41346 8.12238 3.07458 7.29387L0.0480979 -0.105391C-0.221009 -0.763314 0.262949 -1.48406 0.973779 -1.48397L7.02744 -1.48328Z" fill="%231D2939"/></g><defs><clipPath id="clip0_2560_192491"><rect width="8" height="8" fill="white"/></clipPath></defs></svg>')
        no-repeat !important;
    bottom: -3px !important;
    display: none;
}

#logout-tooltip .custom-arrow.light-blue {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfNzQ2M18xMDMxOCkiPgo8cGF0aCBkPSJNOS40ODE3NCA3LjAyNjc0QzkuNDgxNDEgNy43Mzc0MSA4Ljc2MDY5IDguMjIwOTUgOC4xMDI5NyA3Ljk1MTc3TDAuNzA1MjMgNC45MjQxNUMtMC4xMjMyODEgNC41ODUwNyAtMC4xMjMwMTMgMy40MTE3IDAuNzA1NjUyIDMuMDczTDguMTA2MTcgMC4wNDgxNDc0QzguNzY0MTkgLTAuMjIwODEyIDkuNDg0ODUgMC4yNjMzOTcgOS40ODQ1MiAwLjk3NDI2N0w5LjQ4MTc0IDcuMDI2NzRaIiBmaWxsPSIjMDg4QUIyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNzQ2M18xMDMxOCI+CjxyZWN0IHdpZHRoPSI4IiBoZWlnaHQ9IjgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==")
        no-repeat !important;
    transform: initial !important;
    left: -3.5px !important;
    bottom: -3px !important;
}

#status-success-tooltip .custom-arrow.green {
    top: 9px !important;
    /* transform: translateY(-50%) !important; */
}

#tour-tooltip .custom-arrow.light-blue {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfNzQ2M18xMDMxOCkiPgo8cGF0aCBkPSJNOS40ODE3NCA3LjAyNjc0QzkuNDgxNDEgNy43Mzc0MSA4Ljc2MDY5IDguMjIwOTUgOC4xMDI5NyA3Ljk1MTc3TDAuNzA1MjMgNC45MjQxNUMtMC4xMjMyODEgNC41ODUwNyAtMC4xMjMwMTMgMy40MTE3IDAuNzA1NjUyIDMuMDczTDguMTA2MTcgMC4wNDgxNDc0QzguNzY0MTkgLTAuMjIwODEyIDkuNDg0ODUgMC4yNjMzOTcgOS40ODQ1MiAwLjk3NDI2N0w5LjQ4MTc0IDcuMDI2NzRaIiBmaWxsPSIjMDg4QUIyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNzQ2M18xMDMxOCI+CjxyZWN0IHdpZHRoPSI4IiBoZWlnaHQ9IjgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==")
        no-repeat !important;
    transform: initial !important;
    left: -3.5px !important;
    top: 146px !important;
}

#tour-tooltip {
    position: absolute;
    top: -40px !important;
    right: 50% !important;
    left: 58px !important;
    transform: translateY(-50%);
}

#logout-tooltip {
    position: absolute;
    top: 50% !important;
    left: 140% !important;
    transform: translateY(-50%);
}

[class*="react-tooltip__place-top"] > .custom-arrow {
    transform: rotate(1deg) !important;
    margin-bottom: -4px;
}

[class*="react-tooltip__place-right"] > .custom-arrow {
    transform: rotate(90deg) !important;
    margin: 0 -4px !important;
}

[class*="react-tooltip__place-bottom"] > .custom-arrow {
    transform: rotate(180deg) !important;
    margin: -4px 0 !important;
}

.custom_arrow::before {
    content: "";
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfNzQ2M18xMDMxOCkiPgo8cGF0aCBkPSJNOS40ODE3NCA3LjAyNjc0QzkuNDgxNDEgNy43Mzc0MSA4Ljc2MDY5IDguMjIwOTUgOC4xMDI5NyA3Ljk1MTc3TDAuNzA1MjMgNC45MjQxNUMtMC4xMjMyODEgNC41ODUwNyAtMC4xMjMwMTMgMy40MTE3IDAuNzA1NjUyIDMuMDczTDguMTA2MTcgMC4wNDgxNDc0QzguNzY0MTkgLTAuMjIwODEyIDkuNDg0ODUgMC4yNjMzOTcgOS40ODQ1MiAwLjk3NDI2N0w5LjQ4MTc0IDcuMDI2NzRaIiBmaWxsPSIjMDg4QUIyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNzQ2M18xMDMxOCI+CjxyZWN0IHdpZHRoPSI4IiBoZWlnaHQ9IjgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==")
        no-repeat !important;
    position: absolute;
    width: 16px;
    height: 16px;
}
.right_custom_arrow::after {
    content: "";
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfODgxMl8xMTgyMzApIj4KPHBhdGggZD0iTS0xLjQ4Mjk2IDcuMDI3NzFDLTEuNDgyNjMgNy43MzgzOSAtMC43NjE5MSA4LjIyMTkzIC0wLjEwNDE4OSA3Ljk1Mjc1TDcuMjkzNTUgNC45MjUxMkM4LjEyMjA2IDQuNTg2MDUgOC4xMjE3OSAzLjQxMjY4IDcuMjkzMTMgMy4wNzM5N0wtMC4xMDczODcgMC4wNDkxMjRDLTAuNzY1NDE1IC0wLjIxOTgzNSAtMS40ODYwNyAwLjI2NDM3MyAtMS40ODU3NCAwLjk3NTI0NEwtMS40ODI5NiA3LjAyNzcxWiIgZmlsbD0iIzA4OEFCMiIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzg4MTJfMTE4MjMwIj4KPHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iOCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K")
        no-repeat !important;
    position: absolute;
    width: 16px;
    height: 16px;
}
.custom_arrow.step2::before {
    left: -8px;
    top: 20px;
}
.custom_arrow.step3::before {
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
}

.right_custom_arrow.step4::after {
    right: -6%;
    top: 50%;
    transform: translateY(-50%);
}
.right_custom_arrow.step5::after {
    right: 45.5%;
    top: -4.8%;
    transform: rotate(270deg);
}

.right_custom_arrow.step6::after {
    right: -6%;
    top: 8%;
}
.right_custom_arrow.step7::after {
    right: -6%;
    top: 34px;
}

.right_custom_arrow.step8::after {
    right: -6%;
    top: 11.5%;
}

.right_custom_arrow.step9::after {
    right: 45%;
    top: -4.3%;
    transform: rotate(270deg);
}
.right_custom_arrow.step10::after {
    right: 13%;
    top: -5%;
    transform: rotate(270deg);
}
.right_custom_arrow.step11::after {
    right: 14%;
    top: -6.7%;
    transform: rotate(270deg);
}

@media screen and (min-width: 959px) and (max-width: 1439px) {
    .right_custom_arrow.step11::after {
        right: 12%;
    }
}
.right_custom_arrow.step12::after {
    right: -6%;
    top: 11.5%;
}

.tag-input .css-1wy0on6 {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 22px !important;
}

#trackpack-hub .custom_arrow.step2::before {
    top: 50%;
    transform: translateY(-50%);
}

#trackpack-hub .right_custom_arrow.step6::after {
    top: 103px;
}
@media only screen and (min-width: 960px) and (max-width: 1439px) {
    #trackpack-hub .right_custom_arrow.step6::after {
        top: 28px;
    }
}

#trackpack-hub .right_custom_arrow.step7::after {
    top: 78.2%;
    transform: translateY(-78.5%);
}

@media only screen and (min-width: 960px) and (max-width: 1439px) {
    #trackpack-hub .right_custom_arrow.step7::after {
        top: 50%;
        transform: translateY(-50%);
        right: -8%;
    }
}

#trackpack-hub .right_custom_arrow.step8::after {
    top: 50%;
    transform: translateY(-50%);
    right: -6%;
}

@media only screen and (min-width: 960px) and (max-width: 1439px) {
    #trackpack-hub .right_custom_arrow.step8::after {
        right: -8%;
    }
    #trackpack-hub .right_custom_arrow.step8.step9::after {
        right: -7%;
    }
}
